import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import styled from "styled-components";

const Despre = () => {
  return (
    <Layout>
      <Seo title="Despre noi" />
      <Stele></Stele>
      <Wrapper>
        <DespreWrapper>
          <Paragraf>
            <Titlu>Povestea "Garlic"</Titlu>
            <br />
            <Subtitlu>
              A fost odată ca-n povești, a fost mai de demult, în Munții
              Transilvaniei, un conte de temut... Așa începe povestea noastră,
              iar continuarea vă invităm să o descoperiți la 𝗚𝗔𝗥𝗟𝗜𝗖. <br />
              <br />
              Cu treabă bună, pe îndelete, așa ca în Ardeal, de zor și cu mult
              spor, gătim și pregătim tot felul de bunătăți. Vestitul 𝘂𝘀𝘁𝘂𝗿𝗼𝗶
              este de neînlocuit la noi în bucătărie și de nelipsit în legendele
              care au străbătut timpul. <br />
              <br /> Se spune că usturoiul ține vampirii departe, dar am văzut
              și că atrage oamenii faini, cu poftă de viață și de preparate
              delicioase, așa că te așteptăm cu mare drag!
            </Subtitlu>
          </Paragraf>

          <Poza src="/images/bistro/raft.png"></Poza>
        </DespreWrapper>
        <Desprenoi>
          <Titlu>GARLIC BISTRO</Titlu>
          <Subtitlu>
            În centru Clujului, inima Transilvaniei, am creat un loc care îmbină
            frumusețea ardelenească, cu misterul și profunzimea legendelor
            zonei. ​<br />​<br /> Garlic Bistro e un loc de poveste în care te
            invităm să te lași vrăjit de aromele preparatelor noastre. ​​
            <br />​<br /> Legenda spune că odată ce le guști, te vei întoarce de
            fiecare dată când ai ocazia. Poate e doar un mit, așa că cel mai
            bine e să vii și să încerci! ​ ​<br />
            ​<br />
            Te așteptăm cu drag, să ai parte de momente de poveste la Garlic
            Bistro. ​ ​<br />
            ​<br />
            Până atunci, nu uita ce spune legenda:{" "}
            <Strong>a bite of garlic a day, keeps vampires away!</Strong>
          </Subtitlu>
        </Desprenoi>
        <LocatieWrapper id="locatie">
          <Titlu>Locație</Titlu>
          <Strada>
            <Overlay>
              <Title>Terasa Stradă</Title>
            </Overlay>
          </Strada>
          <Middle>
            <Left>
              <Bistro>
                <Overlay>
                  <Title>Bistro Interior</Title>
                </Overlay>
              </Bistro>
              <Spate>
                <Overlay>
                  <Title>Terasa Spate</Title>
                </Overlay>
              </Spate>
            </Left>
            <Curte>
              <Overlay>
                <Title>Terasa Curte</Title>
              </Overlay>
            </Curte>
          </Middle>
        </LocatieWrapper>
      </Wrapper>
    </Layout>
  );
};

export default Despre;

const Desprenoi = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 600px;
  align-self: center;
  margin-top: 50px;
  margin-bottom: 100px;
  @media (max-width: 760px) {
    margin: 20px;
    margin-bottom: 100px;
  }
`;
const Paragraf = styled.div`
  max-width: 600px;
  text-align: center;
`;
const Poza = styled.img`
  height: 500px;
`;
const Subtitlu = styled.text`
  font-weight: normal;
  font-size: 14px;
  line-height: 110%;
  color: #ffffff;
  opacity: 0.7;
  text-align: center;
  font-style: italic;
`;
const Strong = styled.text`
  font-weight: 600;
`;
const Titlu = styled.text`
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
  color: #ffffff;
  margin-bottom: 16px;
  opacity: 1;
  text-align: center;
`;

const DespreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 760px) {
    margin: 20px;
    margin-top: 50px;
    flex-flow: column;
  }
`;

const Stele = styled.div`
  background-image: url("/images/backgrounds/fundal.png");
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0%;
  z-index: -1;
`;
const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-flow: column;
  margin-top: 100px;
`;

const Overlay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.text`
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  /* or 15px */
  color: #ffffff;
  opacity: 0.8;
  text-align: center;
`;

const LocatieWrapper = styled.div`
  margin: auto;
  text-align: center;
  @media (max-width: 680px) {
    width: 100%;
  }
`;
const Left = styled.div`
  @media (max-width: 680px) {
    width: 40%;
    margin-right: 10px;
  }
`;

const Strada = styled.div`
  width: 610px;
  height: 200px;
  margin: 5px;
  margin-top: 20px;
  background: rgba(0, 0, 0, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url("/images/bistro/strada.jpg");
  background-size: cover;
  background-position: bottom;
  @media (max-width: 680px) {
    width: 100%;
    margin-right: 10px;
  }
`;

const Middle = styled.div`
  display: flex;
`;
const Bistro = styled.div`
  height: 400px;
  width: 280px;
  margin: 5px;
  background: rgba(0, 0, 0, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url("/images/bistro/interior.jpg");
  background-size: cover;
  background-position: center;
  @media (max-width: 680px) {
    width: 100%;
  }
`;
const Curte = styled.div`
  height: 500px;
  width: 320px;
  margin: 5px;
  background: rgba(0, 0, 0, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url("/images/bistro/curte.jpg");
  background-size: cover;
  background-position: center;

  @media (max-width: 680px) {
    width: 60%;
  }
`;

const Spate = styled.div`
  height: 150px;
  width: 280px;
  margin: 5px;
  background: rgba(0, 0, 0, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url("/images/bistro/spate.jpg");
  background-size: cover;
  background-position: center;
  @media (max-width: 680px) {
    width: 100%;
  }
`;
